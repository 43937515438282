var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-pagination", {
    staticClass: "iq-pagination",
    attrs: {
      "page-size": _vm.pageSize,
      "page-count": _vm.pageCount,
      "pager-count": _vm.pagerCount,
      "current-page": _vm.currentPage,
      layout: _vm.layout,
      total: _vm.total,
    },
    on: { "current-change": _vm.changePage },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }