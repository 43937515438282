<template>
  <el-pagination
    class="iq-pagination"
    :page-size="pageSize"
    :page-count="pageCount"
    :pager-count="pagerCount"
    :current-page="currentPage"
    :layout="layout"
    :total="total"
    @current-change="changePage"
  />
</template>

<script>
export default {
  name: 'IqPagination',
  props: {
    pageSize: {
      type: Number,
      default: 0,
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    pagerCount: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    layout: {
      type: String,
      default: 'prev, pager, next',
    },
  },
  methods: {
    changePage(event) {
      this.$emit('onChangePage', event)
    },
  },
}
</script>

<style lang="sass">
.iq-pagination
  display: flex
  justify-content: flex-start
  align-items: center
  .el-pager
    display: flex
    justify-content: flex-start
    align-items: center

    li
      font-weight: 500
      font-size: 12px
      line-height: 18px
      font-feature-settings: 'tnum' on, 'lnum' on
      color: $main-font
      display: flex
      justify-content: center
      align-items: center
      &.active
        color: $color-blue-primary
</style>
